import React, { useEffect, useRef, useState } from "react";
import "./admindashboard.scss";
import { Card } from "primereact/card";
import Button from "@mui/material/Button";
import axios from "axios";
import { Toast } from "primereact/toast";
import { InputSwitch } from "primereact/inputswitch";
import moment from "moment";
import { Link } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import abi from "../../../ABI.json";
export const AdminDashboard = () => {
  const contract = "0x11B2E4e9329685c758943B2b07CdAc7CC56665D2";
  const [toggle, setToggle] = useState(true);
  const [memid, stateChange] = useState("");
  const [memberstatus, setStatus] = useState(true);
  const [forDate, setFfrDate] = useState(null);
  const toast = useRef(null);
  const [coin, setCoin] = useState(0);
  const [data, setData] = useState({
    custDisbursh: 0,
    disDate: "",
  });
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [ismemchange, setMemchange] = useState(false);
  const [isButtonDisabled1, setButtonDisabled1] = useState(false);
  const [dailyDisbursh, setDailyDisbursh] = useState({});
  const url = process.env.REACT_APP_HOST_ADDR;
  const validationSchema = Yup.object({
    disdate: Yup.date("Invalid Date"),
  });
  const formik = useFormik({
    initialValues: {
      disdate: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      const { disdate } = values;
      setButtonDisabled(true);
      const resp = await axios.post(url + "/admin/setdisbursh", {
        toggle,
        disdate,
      });
      if (resp.status === 200) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Disbursh Mode Set Successfully.",
        });
        setButtonDisabled(false);
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Disbursh Mode Set Successfully.",
        });
        setButtonDisabled(false);
      }
    },
  });
  useEffect(() => {
    const fetchData = async () => {
      const resp = await axios.get(url + "/admin/getdisbursh");
      var a = moment(resp.data.resp.dis_date);
      var _date = a.utcOffset(300).format("YYYY-MM-DD");
      setData({ disDate: _date });
      setFfrDate(resp.data.forDate);
      setDailyDisbursh(resp.data.disburshList);
      formik.setFieldValue("disdate", _date);
      if (resp.data.resp.cust_date === 1) {
        setToggle(true);
      } else {
        setToggle(false);
      }
    };
    fetchData();
  }, []);
  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    );
  };
  const submitCoin = async () => {
    const resp = await axios.post(url + "/admin/chektopup", { wa: "1" });
    // const provider = new ethers.providers.Web3Provider(window.ethereum);
    // await provider.send("eth_requestAccounts", []);
    // const signer = provider.getSigner();
    // var mycontract = new ethers.Contract(contract, abi, signer);
    // var soar = coin
    // soar = soar.toString()
    // mycontract.contribute({value:ethers.utils.parseUnits(soar,"ether")}).then(function(resp){
    //   alert("done")
    // })
  };
  const memblockunblock = async () => {
    setMemchange(true);
    const memstatus = memberstatus === true ? "1" : "0";
    const resp = await axios.post(url + "/admin/memstatusupd", {
      memid,
      memstatus,
    });
    if (resp.status === 200) {
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "Withdrawal Updated Successfully.",
      });
      setMemchange(false);
    }
  };
  const fetchSPGold = async () => {
    setButtonDisabled(true);
    const resp = await axios.get(url + "/admin/fetchspgold");
    if (resp.status === 200) {
      setButtonDisabled(false);
      if (resp.data.data === 2) {
        alert("ALL DATA UPDATED..");
      }
    }
  };
  const modifier = async () => {
    // const resp = await axios.get(url + "/admin/modifier");
    // console.log(resp);
  };
  const startDisburs = async () => {
    const resp = await axios.post(url + "/admin/startdisburs", {
      forDate,
    });
    if (resp.status === 200) {
      toast.current.show({
        severity: "success",
        summary: "Seccess",
        detail: "Disbursh Date Set Successfully.",
      });
      setTimeout(() => {
        window.location.reload(true)
      }, 2000);
    } else {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Something went wrong.",
      });
    }
  };
  const endDisbursh =async () =>{
    const resp = await axios.post(url + "/admin/enddisburs", {});
    if (resp.status === 200) {
      toast.current.show({
        severity: "success",
        summary: "Seccess",
        detail: "Disbursh Ended Successfully.",
      });
      setTimeout(() => {
        window.location.reload(true)
      }, 2000);
    } else {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Something went wrong.",
      });
    }
  }
  return (
    <div className="admindashboard">
      <Toast ref={toast} />
      <div className="flex gap-3 mb-3 align-items-center border-2 border-dashed surface-border border-round surface-ground p-2">
        {forDate}
        {dailyDisbursh && dailyDisbursh.tran_date === forDate ? (
          <Button
            type="button"
            variant="contained"
            color="warning"
            disabled
          >
            START DISBURS
          </Button>
        ) : (
          <Button
            type="button"
            variant="contained"
            color="warning"
            onClick={() => startDisburs()}
          >
            START DISBURS
          </Button>
        )}
        {dailyDisbursh &&
        dailyDisbursh.tran_date === forDate &&
        dailyDisbursh.is_closed === 0 ? (
          <Button
            type="button"
            variant="contained"
            color="warning"
            onClick={() => endDisbursh()}
          >
            END DISBURS
          </Button>
        ) : (
          <Button
            type="button"
            variant="contained"
            color="warning"
            disabled
          >
            END DISBURS
          </Button>
        )}
      </div>
      <div className="flex gap-2 border-2 border-dashed surface-border border-round surface-ground p-2 justify-content-between">
        {dailyDisbursh && dailyDisbursh.fetch_sp_gold === 0 ? (
          <Button
            type="button"
            variant="contained"
            onClick={() => fetchSPGold()}
          >
            1. Fetch SP-GOLD
          </Button>
        ) : (
          <Button
            type="button"
            variant="contained"
            disabled
          >
            1. Fetch SP-GOLD
          </Button>
        )}
        {dailyDisbursh && dailyDisbursh.fetch_sp_gold === 1 && dailyDisbursh.weekly === 0  ? (
          <Button
            type="button"
            variant="contained"
            href="/admin/dailyweekly"
          >
            2. WEEKLY
          </Button>
        ) : (
          <Button
            type="button"
            variant="contained"
            disabled
            href="/admin/dailyweekly"
          >
            2. WEEKLY
          </Button>
        )}
        {dailyDisbursh && dailyDisbursh.weekly === 1 && dailyDisbursh.level === 0 ? (
          <Button type="button" variant="contained" href="/admin/dailylevel">
            3. Level
          </Button>
        ) : (
          <Button
            type="button"
            variant="contained"
            disabled
            href="/admin/dailylevel"
          >
            3. Level
          </Button>
        )}
        {dailyDisbursh && dailyDisbursh.level === 1 && dailyDisbursh.roi === 0 ? (
          <Button type="button" variant="contained" href="/admin/roi">
            4. ROI
          </Button>
        ) : (
          <Button type="button" variant="contained" disabled href="/admin/roi">
            4. ROI
          </Button>
        )}
        {dailyDisbursh && dailyDisbursh.roi === 1 && dailyDisbursh.calc_sup_gold===0 ? (
          <Button type="button" variant="contained" href="/admin/spgoldcalc">
            5. Calc SP GOLD
          </Button>
        ) : (
          <Button
            type="button"
            disabled
            variant="contained"
            href="/admin/spgoldcalc"
          >
            5. Calc SP GOLD
          </Button>
        )}
        {dailyDisbursh && dailyDisbursh.calc_sup_gold===1 && dailyDisbursh.sup_plt===0?
        <Button
        type="button"
        variant="contained"
        href="/admin/superplatinum"
      >
        6. Sup Plt
      </Button>
        :
        <Button
          type="button"
          disabled
          variant="contained"
          href="/admin/superplatinum"
        >
          6. Sup Plt
        </Button>
        }
        {dailyDisbursh && dailyDisbursh.sup_plt===1 && dailyDisbursh.gold===0?
        <Button
        type="button"
        variant="contained"
        href="/admin/gold"
      >
        7. Gold
      </Button>
        :
        <Button
          type="button"
          disabled
          variant="contained"
          href="/admin/gold"
        >
          7. Gold
        </Button>
        }
        {dailyDisbursh && dailyDisbursh.gold===1 && dailyDisbursh.reward===0?
        <Button
        type="button"
        variant="contained"
        href="/admin/reward"
      >
        8. Reward
      </Button>
        :
        <Button
          type="button"
          disabled
          variant="contained"
          href="/admin/reward"
        >
          8. Reward
        </Button>
        }
        {dailyDisbursh && dailyDisbursh.reward===1 && dailyDisbursh.sup_gold !== 1?
        <Button
        type="button"
        variant="contained"
        href="/admin/supergold"
      >
        9. Sup Gold
      </Button>
        :
        <Button
          type="button"
          disabled
          variant="contained"
          href="/admin/supergold"
        >
          9. Sup Gold
        </Button>
        }
        
      </div>
      <div className="flex gap-3 mt-4 border-2 border-dashed surface-border border-round surface-ground p-2 justify-content-between">
        {moment(forDate).day() === 0 ? (
          <Button
            type="button"
            variant="contained"
            color="secondary"
            href="/admin/silver"
          >
            10. SILVER [SUNDAY]
          </Button>
        ) : (
          <Button
            type="button"
            variant="contained"
            color="secondary"
            disabled
            href="/admin/silver"
          >
            10. SILVER [SUNDAY]
          </Button>
        )}
        {moment(forDate).day() === 0 ? (
          <Button
            type="button"
            variant="contained"
            color="secondary"
            href="/admin/platinum"
          >
            11. PLATINUM [SUNDAY]
          </Button>
        ) : (
          <Button
            type="button"
            variant="contained"
            color="secondary"
            disabled
            href="/admin/platinum"
          >
            11. PLATINUM [SUNDAY]
          </Button>
        )}
        {moment(forDate).day() === 3 ? (
          <Button
            type="button"
            variant="contained"
            color="secondary"
            href="/admin/gbot"
          >
            12. GBOT [WEDNESDAY]
          </Button>
        ) : (
          <Button
            type="button"
            variant="contained"
            color="secondary"
            disabled
            href="/admin/gbot"
          >
            12. GBOT [WEDNESDAY]
          </Button>
        )}
      </div>
      <div className="flex gap-3 mt-3 border-2 border-dashed surface-border border-round surface-ground p-2">
      <Button href="/admin/addresschange" variant="contained">Change Address</Button>
      {/* <Button href="/admin/sponsorchange" variant="contained">Sponsor Change</Button> */}
      {/* <Button href="/admin/businesscheck" label="Business Check">Business Check</Button>
      <Button href="/admin/checkinvest" label="CHECK INVEST">CHECK INVEST</Button> */}
      </div>
      <div className="grid mt-2">
        
        <div className="col-12 md:col-6 lg:col-4">
          <div className="p-1 border-round-sm font-bold">
            <Card>
              <div className="form-group">
                <div>
                  <InputSwitch
                    checked={memberstatus}
                    onClick={() => setStatus(!memberstatus)}
                  />
                </div>
                <div>
                  <div>Member Id Withdraw On Off</div>
                  <InputText
                    type="number"
                    name="memberid"
                    className="p-inputtext-sm"
                    placeholder="Member Id"
                    value={memid}
                    onChange={e => stateChange(e.target.value)}
                  />
                  {getFormErrorMessage("memberid")}
                </div>
                {ismemchange === false ? (
                  <Button
                    type="button"
                    variant="contained"
                    onClick={() => memblockunblock()}
                    label="Submit"
                  >Submit</Button>
                ) : (
                  <Button
                    type="button"
                    variant="contained"
                    icon="pi pi-spin pi-spinner"
                    label="Submitting..."
                  >Submitting..</Button>
                )}
              </div>
            </Card>
          </div>
        </div>
         <div className="col-12 md:col-6 lg:col-4">
          <div className="p-1 border-round-sm font-bold">
            <Card>
              <FormikProvider value={formik}>
                <Form onSubmit={formik.handleSubmit} autoComplete="off">
                  <div className="form-group">
                    <div>
                      <InputSwitch
                        checked={toggle}
                        onClick={() => setToggle(!toggle)}
                      />
                    </div>
                    <div>
                      <div>DISBURSEH DATE</div>
                      <InputText
                        type="date"
                        name="disdate"
                        className="p-inputtext-sm"
                        placeholder="Disbursh Date"
                        autoFocus
                        onChange={formik.handleChange}
                        value={formik.values.disdate}
                      />
                      {getFormErrorMessage("username")}
                    </div>
                    {isButtonDisabled === false ? (
                      <Button type="submit" variant="contained">Submit</Button>
                    ) : (
                      <Button
                        type="button"
                        variant="contained"
                        icon="pi pi-spin pi-spinner"
                      >Submitting...</Button>
                    )}
                  </div>
                </Form>
              </FormikProvider>
            </Card>
          </div>
        </div> 
      </div>
    </div>
  );
};
