import React, { useEffect, useState, useRef } from "react";
import "./dashboard.scss";
import axios from "axios";
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';
import { Message } from 'primereact/message';
import { useNavigate } from "react-router-dom";
import { DbWelcome } from "../../../components/usercomponent/DbWelcome";
import { DbBusiness } from "../../../components/usercomponent/DbBusiness";
export const DashBoard = () => {
  const Navigate = useNavigate();
  const url = process.env.REACT_APP_HOST_ADDR;
  const [visible, setVisible] = useState(false);
  useEffect(()=>{
    const fetchData = async () => {
      if (window.ethereum) {
        const adrs = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        const wa = adrs[0];
        const dbdata = await axios.post(url + "/user/emailupdate", {
          wa,
        });
        if(dbdata.status===201){
          setVisible(true)
        }else{
          setVisible(false)
        }
      }
    }
    fetchData()
  },[])

const footerContent = (
    <div>
        <Button label="Close" icon="pi pi-check" onClick={() => setVisible(false)} autoFocus />
    </div>
);
  return (
    <div className="dashboard">
      <DbWelcome />
      <Dialog visible={visible} modal header="Email Integration" footer={footerContent} style={{ width: '50rem' }} onHide={() => {if (!visible) return; setVisible(false); }}>
          <p className="m-0">This address is not linked with any email.</p>
          <p className="m-0">Please link the email.</p>
          <Message text="Proceed with Master Link, if It is your main Id" className="mb-2" />
          <Message text="Proceed with Child Link, if It is your Associate Id" severity="warn" />
          <Divider />
          <div className="flex gap-5">
            <Button label="Master Link" severity="info" onClick={()=>Navigate("/user/emailsetup")} />
            <Button label="Child Link" severity="warning" onClick={()=>Navigate("/user/emailchildsetup")} />
          </div>
      </Dialog>
    </div>
  );
};
