import React, { useEffect, useState, useRef } from "react";
import { Form, FormikProvider, useFormik } from "formik";
import { Card } from "primereact/card";
import axios from "axios";
import * as Yup from "yup";
import "./emailchildsetup.scss";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Alert } from "@mui/material";
import { Divider } from "primereact/divider";
import { Toast } from "primereact/toast";
import { useNavigate } from "react-router-dom";
export const EmailChildSetup = () => {
    const Navigate = useNavigate();
  const url = process.env.REACT_APP_HOST_ADDR;
  const [btnCheck, setBtnCheck] = useState(0);
  const toast = useRef(null);
  useEffect(()=>{
      const fetchData = async () => {
        if (window.ethereum) {
          const adrs = await window.ethereum.request({
            method: "eth_requestAccounts",
          });
          const wa = adrs[0];
         formik.setFieldValue("actaddress",wa)
        }
      }
      fetchData()
    },[])
  const validationSchema = Yup.object({
    actaddress: Yup.mixed("Invalid Investor Id").required(
      "Top-Up Id cant not be blank."
    ),
    email: Yup.mixed("Invalid Investor Id").required(
      "Top-Up Id cant not be blank."
    ),
    masteradd: Yup.mixed("Invalid Investor Id").required(
      "Top-Up Id cant not be blank."
    ),
    memname: Yup.mixed("Invalid Investor Id").required(
      "Top-Up Id cant not be blank."
    ),
  });
  const formik = useFormik({
    initialValues: {
      actaddress: "",
      email: "",
      masteradd: "",
      memname: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      const resp = await axios.post(url + "/user/clientemailconf", { values });
      if (resp.status === 200) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Linked Successfully",
        });
        setTimeout(() => {
            Navigate("/user/")
          }, 2000);
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Something Went Wrong....",
        });
      }
    },
  });
  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    );
  };
  const sendOTP = async () => {
    const email = formik.values.email;
    if (email === "" ) {
      alert("Blank");
      return false;
    }
    const resp = await axios.post(url + "/user/fetchmaster", {
      email,
    });
    if (resp.status === 200) {
      formik.setFieldValue("masteradd",resp.data.data.mem_address)
      formik.setFieldValue("memname",resp.data.data.user_name)
      setBtnCheck(1)
    } else {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: resp.data.error,
      });
    }
  };
  return (
    <div className="emailchildsetup">
      <Toast ref={toast} />
      <div className="grid">
        <div className="col-12 md:col-6 lg:col-7">
          <div className="p-1 border-round-sm font-bold">
            <Card className="content" title="Associated Link">
              <FormikProvider value={formik}>
                <Form onSubmit={formik.handleSubmit} autoComplete="off">
                  <div className="form-group flex flex-column gap-2">
                    <InputText
                      type="text"
                      name="actaddress"
                      className="p-inputtext-sm"
                      placeholder="Email Address"
                      readOnly
                      onChange={formik.handleChange}
                      value={formik.values.actaddress}
                    />
                    <InputText
                      type="email"
                      name="email"
                      className="p-inputtext-sm"
                      placeholder="Master Email"
                      autoFocus
                      disabled={btnCheck === 0 ? false : true}
                      onChange={formik.handleChange}
                      value={formik.values.email}
                    />
                    <Button
                      label="Check"
                      severity="warning"
                      type="button"
                      onClick={() => sendOTP()}
                      disabled={btnCheck === 0 ? false : true}
                    />
                    <InputText
                      type="text"
                      name="masteradd"
                      className="p-inputtext-sm"
                      placeholder="Master Address"
                      readOnly
                      onChange={formik.handleChange}
                      value={formik.values.masteradd}
                    />
                    <InputText
                      type="text"
                      name="memname"
                      className="p-inputtext-sm"
                      placeholder="Masters Name"
                      readOnly
                      onChange={formik.handleChange}
                      value={formik.values.memname}
                    />
                    <Divider />
                    <Button
                      label="Confirm"
                      severity="info"
                      disabled={btnCheck === 0 ? true : false}
                    />
                  </div>
                  <Alert sx={{ marginBottom: 2, marginTop: 2 }}>
                    This is your Link Account.
                  </Alert>
                </Form>
              </FormikProvider>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};
